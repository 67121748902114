import React from "react";
// router
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import logo from "../../asets/logo.png";
//preklady
import { withNamespaces } from 'react-i18next';
//import i18n from './../../i18n';


const Main=({t})=>{
  return(
    <>
      {/* nadpis */}
      <h1 style={{"color":"#306010"}}>{t('appTitle')}</h1>
      <img alt="logo" width={100} src={logo} /><br /><br />

      <button className="okbtn" title={t('btn1Title')} ><Link to='/reader' style={{textDecoration:"none",color:"black"}}>{t('btnQRcitacka')}</Link></button><br /><br />
      <button className="okbtn" title={t('btn2Title')} ><Link to='/generate' style={{textDecoration:"none",color:"black"}}>{t('btnQRgenerator')}</Link></button><br /><br />
      <span style={{}}><Link to='/about' style={{"color":"#42b983",fontSize:"19px"}}>{t('aboutLink')}</Link></span>
    </>
  )
}

export default withNamespaces()(Main);